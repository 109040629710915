import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { featurePermission } from "../Utils/Utils";
import useReduxData from "../Hooks/useReduxData";
import { RoleTitle } from "../Context/constants";
import MainSkeleton from "../Component/Skeleton/MainSkeleton";

const Appointments = React.lazy(() => import("../Pages/Appointments/Appointments"));
const Availability = React.lazy(() => import("../Pages/Availability/Availability"));
const Insights = React.lazy(() => import("../Pages/Insights/Insights"));
const Notifications = React.lazy(() => import("../Pages/Notifications/Notifications"));
const Offers = React.lazy(() => import("../Pages/Offers/Offers"));
const Patients = React.lazy(() => import("../Pages/Patients/Patients"));
const Services = React.lazy(() => import("../Pages/Expertise/Services"));
const Teams = React.lazy(() => import("../Pages/Teams/Teams"));
const WaitingList = React.lazy(() => import("../Pages/WaitingList/WaitingList"));
const ChangePin = React.lazy(() => import("../Pages/Settings/ChangePin"));
const FAQ = React.lazy(() => import("../Pages/Settings/FAQ"));
const HelpSupport = React.lazy(() => import("../Pages/Settings/HelpSupport"));
const Policies = React.lazy(() => import("../Pages/Settings/Policies"));
const SetAvailbility = React.lazy(() => import("../Pages/Availability/SetAvailbility"));
const UpDateAvailbility = React.lazy(() => import("../Pages/Availability/UpdateAvailbility"));
const Notification = React.lazy(() => import("../Pages/Settings/Notification"));
const SYF = React.lazy(() => import("../Pages/Settings/SYF"));
// profile
const Profile = React.lazy(() => import("../Pages/Profile/Profile"));
const EditClinic = React.lazy(() => import("../Pages/Profile/EditClinic"));
const AddTeams = React.lazy(() => import("../Pages/Teams/AddTeams"));
const TeamDtaeils = React.lazy(() => import("../Pages/Teams/TeamDtaeils"));
const EditTeameProfile = React.lazy(() => import("../Pages/Teams/EditTeameProfile"));
const TeamsAvailability = React.lazy(() => import("../Pages/Teams/TeamsAvailability"));
const TeamsUnAvailability = React.lazy(() => import("../Pages/Teams/TeamsUnAvailability"));
const TeamsAddAvailability = React.lazy(() => import("../Pages/Teams/TeamsAddAvailability"));
const TeamsAvailabilityUpdate = React.lazy(() => import("../Pages/Teams/TeamsAvailabilityUpdate"));
const AddPatients = React.lazy(() => import("../Pages/Patients/AddPatients"));
const AddPet = React.lazy(() => import("../Pages/Patients/AddPet"));
const History = React.lazy(() => import("../Pages/Patients/History"));
const SecviceSEction = React.lazy(() => import("../Pages/Expertise/Expertise"));
const Specialization = React.lazy(() => import("../Pages/Expertise/Specialization"));
const AddOffers = React.lazy(() => import("../Pages/Offers/AddOffers"));
const EditOffers = React.lazy(() => import("../Pages/Offers/EditOffers"));
const AllDetails = React.lazy(() => import("../Pages/Credential/UserDetails/AllDetails"));
const BasicDetails = React.lazy(() => import("../Pages/Credential/UserDetails/BasicDetails"));
const RejectPage = React.lazy(() => import("../Pages/RejectInvitation/rejectPage"));
const Private = React.lazy(() => import("../Layout/Private"));
const CreatePassword = React.lazy(() => import("../Pages/AcceptInvitation/createPin"));
const Public = React.lazy(() => import("../Layout/Public"));
const PrivateErrorPage = React.lazy(() => import("../Pages/ErrorPgae.jsx/PrivateErrorPage"));
const TeamLocation = React.lazy(() => import("../Pages/Teams/TeamLocation"));
const TeamSpecialization = React.lazy(() => import("../Pages/Teams/TeamSpecialization"));
const UpdatePet = React.lazy(() => import("../Pages/Patients/UpdatePet"));
const VaccinationCalendar = React.lazy(() => import("../Pages/VaccinationLibrary/VaccinationCalendar"));
const TodayAgenda = React.lazy(() => import("../Pages/Appointments/TodayAgenda"));
const AppointmentDetails = React.lazy(() => import("../Pages/Appointments/AppointmentDetails"));
const VaccineCalender = React.lazy(() => import("../Pages/Patients/VaccineCalender"));
const VaccineList = React.lazy(() => import("../Pages/Patients/VaccineFormUpdate"));
const EprescriptionHistory = React.lazy(() => import("../Pages/Patients/E-prescriptionHistory"));
const TestReport = React.lazy(() => import("../Pages/Patients/TestReport"));
const UpdatePatient = React.lazy(() => import("../Pages/Patients/UpdatePatient"));
const EPrescription = React.lazy(() => import("../Pages/Patients/E-prescription"));
const BookingDetails = React.lazy(() => import("../Pages/Insights/BookingDetails"));
const PatientReport = React.lazy(() => import("../Pages/Insights/PatientReport"));
const TeamReport = React.lazy(() => import("../Pages/Insights/TeamReport"));
const ServiceReports = React.lazy(() => import("../Pages/Insights/ServiceReports"));
const RushHours = React.lazy(() => import("../Pages/Insights/RushHours"));
const Demographics = React.lazy(() => import("../Pages/Insights/Demographics"));
const Feedback = React.lazy(() => import("../Pages/Insights/Feedback"));
const AddSpecialization = React.lazy(() => import("../Pages/Profile/AddSpecialization"));
const LifeStyle = React.lazy(() => import("../Pages/Settings/LifeStyle"));
const AdditionalDetails = React.lazy(() => import("../Pages/Patients/AdditionalDetails"));
const Forgot = React.lazy(() => import("../Pages/Credential/Forgot"));
const Login = React.lazy(() => import("../Pages/Credential/Login"));
const SignUp = React.lazy(() => import("../Pages/Credential/SignUp"));
const BusinessDetails = React.lazy(() => import("../Pages/Credential/UserDetails/BusinessDetails"));
const AddClinic = React.lazy(() => import("../Pages/Profile/AddClinic"));
const VaccinationForms = React.lazy(() => import("../Pages/VaccinationLibrary/VaccinationForms"));
const CheckOut = React.lazy(() => import("../Pages/Appointments/CheckOut"));
const AppointmentCalendar = React.lazy(() => import("../Pages/Appointments/AppointmentCalendar"));
const TicketList = React.lazy(() => import("../Pages/Settings/Ticket/TicketList"));
const TicketDetails = React.lazy(() => import("../Pages/Settings/Ticket/TicketDetails"));
const Dashboard = React.lazy(() => import("../Pages/dashboard/Dashboard"));
const Mail = React.lazy(() => import("../Layout/Mail"));
// const Dashboard =React.lazy(()=>import("../Pages/dashboard/Dashboard"))

const EditProfile = React.lazy(() => import("../Pages/Profile/EditProfile"));
const Overview = React.lazy(() => import("../Pages/Appointments/Overview"));
const SetUnavailability = React.lazy(() => import("../Pages/Availability/SetUnavailability"));
const Submember = React.lazy(() => import("../Pages/Patients/Submember"));
const SubPatientDetails = React.lazy(() => import("../Pages/Patients/SubPatientDetails"));
const OfferView = React.lazy(() => import("../Pages/Offers/OfferView"));
const Index = React.lazy(() => import("../Pages/Settings/Index"));
const AddTicket = React.lazy(() => import("../Pages/Settings/Ticket/CreateTicket"));
const Other = React.lazy(() => import("../Pages/Settings/Other"));
const AddDegree = React.lazy(() => import("../Pages/Teams/AddDegree"));
const TeamSetUnavailability = React.lazy(() => import("../Pages/Teams/TeamSetUnavailability"));
const AddServices = React.lazy(() => import("../Pages/Expertise/AddServices"));
const EditServices = React.lazy(() => import("../Pages/Expertise/EditServices"));
const VaccineFormUpdate = React.lazy(() => import("../Pages/Patients/VaccineFormUpdate"));
const UnAvailabilityAppointment = React.lazy(() => import("../Pages/Appointments/Un-Availability"));
const VaccinationCategory = React.lazy(() => import("../Pages/VaccinationLibrary/VaccinationCategory"));
const VaccinationCalendarOverview = React.lazy(() => import("../Pages/Appointments/VaccinationCalendarOverview"));
const UpdateCalendarOverview = React.lazy(() => import("../Pages/Appointments/UpdateCalendarOverview"));
const UpdateVaccinationForm = React.lazy(() => import("../Pages/VaccinationLibrary/UpdateVaccinationForm"));
const TeamProfile = React.lazy(() => import("../Pages/Profile/TeamProfile/TeamProfile"));

const CheckoutAppointment = React.lazy(() => import("../Pages/Patients/CheckoutAppointment"));
const CheckoutSlip = React.lazy(() => import("../Pages/Patients/CheckoutSlip"));
const PatientDetails = React.lazy(() => import("../Pages/Patients/PatientDetails"));
const PaymentSuccessfull = React.lazy(() => import("../Pages/AcceptInvitation/PaymentSuccessfull"));
const MyPlan = React.lazy(() => import("../Pages/Settings/MyPlan/MyPlan"));
const BillingHistory = React.lazy(() => import("../Pages/Settings/MyPlan/BillingHistory"));
const PlanHistory = React.lazy(() => import("../Pages/Settings/MyPlan/PlanHistory"));
const PaymentHistory = React.lazy(() => import("../Pages/Settings/MyPlan/paymentHistory"));
const Chat = React.lazy(() => import("../Pages/Chat"));
const PaymentSetting = React.lazy(() => import("../Pages/Settings/PaymentSettting"));



const AllRoutes = (props) => {
  const { reduxData: userdata } = useReduxData("userDataRDX")
  const { reduxData: patientdetails } = useReduxData("patientdetailsRDX")
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const isFrontDeskPermission = userdata?.roleTitle === RoleTitle?.FRONT_DESK
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setAuthenticated(userdata?.userId ? true : false);
    });
  }, [userdata?.userId]);

  if (loading) {
    return <h1 style={{ opacity: 0 }} >Loading...</h1>
  }

  return (
    <>
      <Suspense fallback={<MainSkeleton />}>
        <Routes>
          {/* email template routes */}
          <Route path="/mail" element={<Mail />}  >
            <Route path="acceptInvitation/:token" element={<CreatePassword />} />
            <Route path="acceptInvitation/payment/:token" element={<PaymentSuccessfull />} />
            <Route path="declineInvitation/:token" element={<RejectPage />} />
            <Route path="acceptInvitation/payment/:token/declineInvitation" element={<RejectPage />} />
            <Route path="acceptInvitation/:token/declineInvitation" element={<RejectPage />} />
          </Route>


          {/* Credentials routes */}
          {
            !authenticated ? <Route path="/" element={< Public />}>
              <Route path='/' element={<Navigate replace to={"/login"} />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-pin" element={<Forgot />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/alldetails" element={<AllDetails />}>
                <Route path="/alldetails" element={<Navigate replace to="/alldetails/basic-details" />} />
                <Route path="/alldetails/basic-details" element={<BasicDetails />} />
                <Route path="/alldetails/business-details" element={<BusinessDetails />} />
                <Route path="/alldetails/business-details/addClinic" element={<AddClinic type={'LOGIN'} />} />
                <Route path="/alldetails/business-details/addClinic/addSpecialization" element={<AddSpecialization type={'LOGIN'} />} />
              </Route>
            </Route> :
              <Route path="/" element={<Private />} >
                {/* Dashboard routes */}
                <Route path='/' element={<Navigate replace to={"/dashboard"} />} />
                <Route path="/dashboard" element={<Dashboard />} />
                {/* Appointment routes */}
                <Route path="/appointment/list" element={<Appointments />} />
                <Route path="/appointment/details/:apptId" element={<AppointmentDetails />} />
                <Route path="/appointment/calender" element={<AppointmentCalendar />} />
                <Route path="/appointment/calender/set-unavailability"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <UnAvailabilityAppointment type={"CALENDER"} />
                    </ProtectedRoute>
                  }
                />
                <Route path="/appointment/today-agenda" element={<TodayAgenda />} />
                <Route path="/appointment/today-agenda/overview" element={<Overview />} />
                <Route path="/appointment/today-agenda/overview/:vaccinationCategoryId" element={<VaccinationCalendarOverview />} />
                <Route path="/appointment/today-agenda/overview/:vaccinationCategoryId/:vaccinationFormId" element={<UpdateCalendarOverview />} />
                <Route path="/appointment/today-agenda/:id"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <CheckOut type={"CALENDER"} />
                    </ProtectedRoute>
                  }
                />
                {/* Availability routes */}
                <Route path="/availability" element={<Availability />} />
                <Route path="/availability/set-availability"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <SetAvailbility />
                    </ProtectedRoute>
                  }
                />
                <Route path="/availability/set-unavailability"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <SetUnavailability />
                    </ProtectedRoute>
                  }
                />
                <Route path="/availability/:id"
                  element={<ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                    <UpDateAvailbility />
                  </ProtectedRoute>}

                />
                {/* Waiting List routes */}
                <Route path="/waiting" element={<WaitingList />}/>

                {/* Patients  routes */}
                <Route path="/patients" element={<Patients />} />
                <Route path="/patients/add" element={<AddPatients />} />
                <Route path="/patients/:id/sub-members/add-patient" element={<AddPatients />} />
                <Route path="/patients/:id/sub-members/add-pet" element={<AddPet />} />
                <Route path="/patients/:id" element={<PatientDetails type={"Patients"} />} >
                  <Route path="/patients/:id" element={<Navigate replace to="details" />} />
                  <Route index path="details" element={patientdetails.type === "PET" ? <UpdatePet type={"Patients"} /> : <UpdatePatient type={"Patients"} />} />
                  <Route path="appointments-history" element={<History type={"Patients"} />} />
                  <Route path="e-prescription" element={<EprescriptionHistory type={"Patients"} />} />
                  <Route path="test-report" element={<TestReport type={"Patients"} />} />
                  <Route path="vaccine-calendar" element={<VaccineCalender type={"Patients"} />} />
                  <Route path="vitals" element={<AdditionalDetails type={"Patients"} />} />
                  <Route path="checkout-slip" element={<CheckoutAppointment type={"Patients"} />} />
                  <Route path="sub-members" element={<Submember />} />
                </Route>
                <Route path="/patients/:id/sub-members/:subpatientId" element={<SubPatientDetails type={"subPatients"} />} >
                  <Route path="/patients/:id/sub-members/:subpatientId" element={<Navigate replace to="details" />} />
                  <Route index path="details" element={<UpdatePatient type={"subPatients"} />} />
                  <Route path="detail" element={<UpdatePet type={"subPatients"} />} />
                  <Route path="appointments-history" element={<History type={"subPatients"} />} />
                  <Route path="e-prescription" element={<EprescriptionHistory type={"subPatients"} />} />
                  <Route path="test-report" element={<TestReport type={"subPatients"} />} />
                  <Route path="vaccine-calendar" element={<VaccineCalender type={"subPatients"} />} />
                  <Route path="checkout-slip" element={<CheckoutAppointment type={"subPatients"} />} />
                  <Route path="vitals" element={<AdditionalDetails type={"subPatients"} />} />
                </Route>
                <Route path="/patients/:id/checkout-slip/:ApptId" element={<CheckoutSlip type={"Patients"} />} />
                <Route path="/patients/:id/sub-members/:subpatientId/checkout-slip/:ApptId" element={<CheckoutSlip type={"subPatients"} />} />
                <Route path="/patients/:id/vaccine-calendar/edit/:formId" element={<VaccineFormUpdate type={"Patients"} />} />
                <Route path="/patients/:id/sub-members/:subpatientId/vaccine-calendar/edit/:formId" element={<VaccineFormUpdate type={"subPatients"} />} />
                <Route path="/patients/:id/e-prescription/:prescriptionNumber" element={<EPrescription type={"Patients"} />} />
                <Route path="/patients/:id/sub-members/:subpatientId/e-prescription/:prescriptionNumber" element={<EPrescription type={"subPatients"} />} />
                <Route path="/patients/:id/vaccine-history/vaccine-calender" element={<VaccineCalender type={"Patients"} />} />
                <Route path="/patients/:id/sub-members/:subpatientId/vaccine-history/vaccine-calender" element={<VaccineCalender type={"subPatients"} />} />
                <Route path="/patients/:id/vaccine-history/vaccine-calender/vaccine-list" element={<VaccineList type={"Patients"} />} />
                <Route path="/patients/:id/sub-members/:subpatientId/vaccine-history/vaccine-calender/vaccine-list" element={<VaccineList type={"subPatients"} />} />
                {/* Vaccination  routes */}
                <Route path="/vaccination"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                      <VaccinationCategory />
                    </ProtectedRoute>
                  }
                />
                <Route path="/vaccination/:id"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                      <VaccinationCalendar />
                    </ProtectedRoute>
                  }
                />
                <Route path="/vaccination/:id/add-form"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                      <VaccinationForms />
                    </ProtectedRoute>
                  }
                />
                <Route path="/vaccination/:id/update-form/:formId"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                      <UpdateVaccinationForm />
                    </ProtectedRoute>
                  }
                />
                <Route path="/vaccination/:id/clone/:formId"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                      <VaccinationForms />
                    </ProtectedRoute>
                  }
                />
                {/* Teams  routes */}
                <Route path="/team" element={<Teams />} />
                <Route path="/team/add"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <AddTeams />
                    </ProtectedRoute>
                  }
                />
                <Route path="/team/add-frontdesk"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <AddTeams />
                    </ProtectedRoute>
                  } />
                <Route path="/team/details" element={<TeamDtaeils />} >
                  <Route path="/team/details" element={<Navigate replace to="profile" />} />
                  <Route path="profile" element={<EditTeameProfile />} />
                  <Route path="availability" element={<TeamsAvailability />} />
                  <Route path="unavailability" element={<TeamsUnAvailability />} />
                  <Route path="location" element={<TeamLocation />} />
                </Route>
                <Route path="/team/specialization/:id" element={<TeamSpecialization />} />
                <Route path="/user/profile" element={<EditTeameProfile />} />
                <Route path="/team/availability/set-availability"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <TeamsAddAvailability />
                    </ProtectedRoute>
                  }
                />
                <Route path="/team/details/unavailability/set-unavailability"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <TeamSetUnavailability />
                    </ProtectedRoute>
                  }
                />
                <Route path="/team/availability/:id"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <TeamsAvailabilityUpdate />
                    </ProtectedRoute>
                  }
                />
                <Route path="/team/details/profile/add-degree"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <AddDegree />
                    </ProtectedRoute>
                  }
                />
                <Route path="/team/details/profile/edit-degree/:id"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <AddDegree />
                    </ProtectedRoute>
                  } />
                {/* expertise  routes */}
                <Route path="/expertise" element={<SecviceSEction />} >
                  <Route path="/expertise" element={<Navigate replace to="specialization" />} />
                  <Route path="specialization" element={<Specialization />} />
                  <Route path="services" element={<Services />} />
                </Route>
                <Route path="/expertise/services/add" element={<AddServices />} />
                <Route path="/expertise/services/edit/:id" element={<EditServices />} />
                {/* Insights  routes */}
                <Route path="/insights" element={<Insights />} >
                  <Route path="/insights" element={<Navigate replace to="bookig-details" />} />
                  <Route path="bookig-details"
                    element={
                      <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" })} >
                        <BookingDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="patient-report"
                    element={
                      <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" })} >
                        <PatientReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="staff-report"
                    element={
                      <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" })} >
                        <TeamReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="service-report"
                    element={
                      <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" })} >
                        <ServiceReports />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="rush-hours"
                    element={
                      <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" })} >
                        <RushHours />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="demographics"
                    element={
                      <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" })} >
                        <Demographics />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="feedback"
                    element={
                      <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" })} >
                        <Feedback />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                {/* Notifications  routes */}
                <Route path="/notifications" element={<Notifications />} />
                {/* Offers  routes */}
                <Route path="/offers" element={<Offers />} />
                <Route path="/offers/add" element={<AddOffers />} />
                <Route path="/offers/:id" element={<EditOffers />} />
                <Route path="/offers/view/:id" element={<OfferView />} />
                {/* setting  routes */}
                <Route path="/setting" element={<Index />}>
                  <Route path="/setting" element={<Navigate replace to="change-pin" />} />
                  <Route index path="change-pin" element={<ChangePin />} />
                  <Route path="policies" element={<Policies />} />
                  <Route path="life-style" element={<LifeStyle />} />
                  <Route path="notifications" element={<Notification />} />
                  <Route path="policies" element={<Policies />} />
                  <Route path="help-desk"
                    element={
                      <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "SupportTicket" })} >
                        <TicketList />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="other" element={<Other />} />
                  <Route path="my-plan"
                    element={
                      <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                        <MyPlan />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="payment-setting"
                    element={
                      <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                        <PaymentSetting />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="help-support" element={<HelpSupport />} >
                    <Route path="/setting/help-support" element={<Navigate replace to="feedback" />} />
                    <Route index path="feedback" element={<SYF />} />
                    <Route path="faq" element={<FAQ />} />
                  </Route>
                </Route>
                <Route path="/setting/my-plan/history" element={<BillingHistory />}>
                  <Route path="/setting/my-plan/history" element={<Navigate replace to="plan-history" />} />
                  <Route path="plan-history" element={<PlanHistory />} />
                  <Route path="payment-history" element={<PaymentHistory />} />
                </Route>
                <Route path="/setting/help-desk/:id"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "SupportTicket" })} >
                      <TicketDetails />
                    </ProtectedRoute>
                  }
                />
                <Route path="/setting/help-desk/create-ticket"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "SupportTicket" })} >
                      <AddTicket />
                    </ProtectedRoute>
                  }
                />
                {/* Profile  routes */}
                <Route path="/Profile" element={userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? <Profile /> : <TeamProfile />} />
                <Route path="/Profile/add-degree"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <AddDegree />
                    </ProtectedRoute>}
                />
                <Route path="/Profile/edit-degree/:id"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <AddDegree />
                    </ProtectedRoute>
                  } />
                <Route path="/Profile/edit" element={<EditProfile />} />
                <Route path="/profile/addClinic" element={<AddClinic />} />
                <Route path="/profile/clinic/Edit/:id" element={<EditClinic />} />
                <Route path="/profile/clinic/Edit/:id/editSpecialization" element={<AddSpecialization />} />
                <Route path="/profile/addClinic/addSpecialization" element={<AddSpecialization />} />
                <Route path="/chat"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Chat" })} >
                      <Chat />
                    </ProtectedRoute>
                  }
                />
              </Route>
          }
          <Route path="/*" element={<PrivateErrorPage />}></Route>
        </Routes>

      </Suspense >
    </>
  );
};

export default AllRoutes;
