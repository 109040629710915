// import { RoleTitle } from "../Context/constants";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
export const formatNumber = (number,Fixed=2) => {
  if (number !== null && number !== undefined) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(Fixed) + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(Fixed) + 'K';
    } else {
      return number.toLocaleString();
    }
  } else {
    return ''; // or any other default value if number is undefined or null
  }
};

export const MaskedEmail = (email) => {
  // Mask part of the username with asterisks
  const maskedEmail = email?.replace(/(.*?)@/, (match, p1) => {
    const visiblePart = p1?.substring(0, 2); // Show the first 2 characters
    const maskedPart = p1?.substring(2).replace(/./g, '*'); // Mask the rest
    return visiblePart + maskedPart + '@';
  });
  return maskedEmail;
};


export const PdfItem = (pdfName) => {
  const parts = pdfName?.split('_');
  const mergedValue = parts?.slice(1)?.join('');
  return mergedValue
};

export const TIMEZONE = () => {
  if (timeZone === "Asia/Calcutta" || timeZone === "Asia/Kolkata") {
    let indiaTimezone = "Asia/Kolkata"
    return indiaTimezone
  } else {
    return timeZone
  }
}


export const Dosage = (item) => {

  let dosageString = "--";
  if (item?.medicineIntakeType === "STANDARD") {
    if (item?.medicineIntakeMorning && item?.medicineIntakeAfternoon && item?.medicineIntakeEvening) {
      dosageString = "M-A-E"
    } else if (item?.medicineIntakeMorning && item?.medicineIntakeAfternoon) {
      dosageString = "M-A"
    }
    else if (item?.medicineIntakeAfternoon && item?.medicineIntakeEvening) {
      dosageString = "A-E"
    }
    else if (item?.medicineIntakeEvening && item?.medicineIntakeMorning) {
      dosageString = "M-E"
    }
    else if (item?.medicineIntakeEvening && item?.medicineIntakeMorning) {
      dosageString = "M-E"
    }
    else if (item?.medicineIntakeMorning && !item?.medicineIntakeAfternoon && !item?.medicineIntakeEvening) {
      dosageString = "M"
    }
    else if (!item?.medicineIntakeMorning && item?.medicineIntakeAfternoon && !item?.medicineIntakeEvening) {
      dosageString = "A"
    } else if (!item?.medicineIntakeMorning && !item?.medicineIntakeAfternoon && item?.medicineIntakeEvening) {
      dosageString = "E"
    }
    else {
      dosageString = "--"
    }

  } else {
    dosageString = item?.customMedCount + item?.customMedCountTimeFrame
  }
  return dosageString
}

export const featurePermission = ({ permission = {}, userdata = {}, section }) => {
  let active = true
  // if (userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER) {
    active = permission?.[section]?.isActive ?? true
  // }
  return active
}


