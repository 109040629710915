import React from 'react'
import "./Skeleton.css"
import { Skeleton } from '@mui/material'
import useReduxData from '../../Hooks/useReduxData'
const MainSkeleton = () => {
    const { reduxData: userdata } = useReduxData("userDataRDX")
    return (
        <>
            {
                userdata?.userId ? <>
                    <div className="HEADER_BOX shadow  px-3">
                        <Skeleton variant="circular" width={40} height={40} />
                        <div className='d-flex align-items-center '>
                            <Skeleton variant="circular" width={40} height={40} className='me-3' />
                            <div>
                                <Skeleton variant="text" width={60} height={10} sx={{ fontSize: '1rem' }} />
                                <Skeleton variant="text" width={40} height={10} sx={{ fontSize: '1rem' }} />
                            </div>
                        </div>
                    </div>
                    <div className="MAIN_SKELETON">

                        <div className="SIDEBAR_BOX shadow">
                            <ul>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>
                                <li className='mb-3'><Skeleton variant="circular" width={30} height={30} className='me-3' /></li>

                            </ul>
                        </div>
                        <div className="CONTENT_BOX">

                            
                        </div>

                    </div>
                </> : <h1>Loading....</h1>
            }

        </>
    )
}

export default MainSkeleton
